import { styled, useTheme } from "@mui/material/styles";
import Button from "components/Button";

const ActionButtonWide = styled(Button)(({ theme }) => {
  const { minActionButtonWidth } = useTheme();

  return {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      minWidth: minActionButtonWidth || "448px",
      width: "auto",
    },
  };
});

export default ActionButtonWide;
