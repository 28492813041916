const TabletIcon = ({ color = "var(--primary-color)", ...props }) => (
  <svg height="1em" viewBox="0 0 26 35" width="1em" {...props}>
    <path
      d="M23.125.933H2.867a2.304 2.304 0 00-2.305 2.31v29.376a2.306 2.306 0 002.305 2.308h20.258a2.308 2.308 0 002.306-2.308V3.242A2.305 2.305 0 0023.125.933zm-10.128 33.13a1.37 1.37 0 110-2.742 1.37 1.37 0 010 2.741zm10.101-3.944H2.896V3.707h20.202V30.12z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export default TabletIcon;
