import Box from "@mui/material/Box";
import styles from "./styles.module.scss";

const Card = ({ children }) => (
  <Box className={styles.card} display="flex" flexDirection="column" justifyContent="center" p={2}>
    {children}
  </Box>
);

export default Card;
