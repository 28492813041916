import { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Button from "components/Button";
import Modal from "components/Modal";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import requestToServer from "utils/requestToServer";
import getEnv from "utils/getEnv";
import useCountdown from "hooks/useCountdown";
import useOnUserInteraction from "hooks/useOnUserInteraction";
import { eventNames, trackEvent } from "utils/EventsTracking";
import t from "../translate";
import LogoutIcon from "./LogoutIcon";

export const AUTOLOGOUT_INITIAL_VALUE_IN_SECONDS = parseInt(getEnv("autoLogoutInitialValueInSeconds", 45), 10);
const COUNTDOWN_INITIAL_VALUE_IN_SECONDS = parseInt(getEnv("countdownInitialValueInSeconds", 10), 10);
const AUTOLOGOUT_MODAL = "autologout";
const CONFIRM_LOGOUT_MODAL = "confirm";

const LogOut = ({ autologoutTimeoutInSeconds, hidden, path }) => {
  const { isMobileKiosk, isSelfCheckInKiosk, palette } = useTheme();
  const [modal, setModal] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);

  const handleLogout = () => {
    if (path && !isSubmitting) {
      setSubmitting(true);
      requestToServer({ method: "DELETE", path });
    }
  };

  const handleAutologoutCountdownFinished = () => {
    setModal(AUTOLOGOUT_MODAL);
    trackEvent(eventNames.KIOSK_AUTOLOGOUT_TRIGGERED);
  };
  const handleAutologoutCancel = () => {
    setModal("");
    trackEvent(eventNames.KIOSK_AUTOLOGOUT_CANCELLED);
  };

  const { countdown: logOutCountdownValue } = useCountdown({
    timeout: COUNTDOWN_INITIAL_VALUE_IN_SECONDS,
    enabled: !!modal,
    onCountdownFinished: handleLogout,
  });

  const { resetCountdown } = useCountdown({
    timeout: autologoutTimeoutInSeconds,
    enabled: modal !== AUTOLOGOUT_MODAL,
    onCountdownFinished: handleAutologoutCountdownFinished,
  });

  useOnUserInteraction(resetCountdown);

  const modalProps =
    modal && modal === AUTOLOGOUT_MODAL
      ? {
          doneButtonProps: { disabled: isSubmitting },
          doneButtonText: t("im_still_here"),
          onDoneButtonClick: handleAutologoutCancel,
          title: t("are_you_still_there"),
        }
      : {
          doneButtonProps: { disabled: isSubmitting, loading: isSubmitting },
          cancelButtonProps: { disabled: isSubmitting },
          doneButtonText: t("logout"),
          onCancelButtonClick: () => setModal(""),
          onDoneButtonClick: handleLogout,
          title: t("are_you_sure"),
        };

  const loaderVariant = isSubmitting ? "indeterminate" : "determinate";

  return (
    <>
      {!hidden && (
        <Button aria-label={t("logout")} onClick={() => setModal(CONFIRM_LOGOUT_MODAL)} variant="outlined">
          <Box sx={{ alignItems: "center", display: "flex", px: { sm: 2 } }}>
            <Typography
              sx={{ display: { xs: "none", sm: "flex" }, paddingRight: 2, whiteSpace: "nowrap" }}
              variant="button"
            >
              {t("logout")}
            </Typography>
            <LogoutIcon color={palette.primary["400"]} />
          </Box>
        </Button>
      )}
      <Modal container={document.body} open={!!modal} shrink {...modalProps}>
        <Typography align="center" component="div" sx={{ marginBottom: 6 }} variant="body1">
          {t("you_will_be_logged_out")}
        </Typography>
        <Box display="flex" justifyContent="center" mb={4} position="relative">
          <CircularProgress
            size={167}
            value={(logOutCountdownValue * 100) / COUNTDOWN_INITIAL_VALUE_IN_SECONDS}
            variant={loaderVariant}
          />
          <Box
            alignItems="center"
            bottom={0}
            display="flex"
            justifyContent="center"
            left={0}
            position="absolute"
            right={0}
            top={0}
          >
            <Typography color="primary" component="div" variant="h2">{`${logOutCountdownValue}s`}</Typography>
          </Box>
        </Box>
        {!(isMobileKiosk || isSelfCheckInKiosk) && (
          <Box mt={6}>
            <Typography align="center" component="div" variant="body1">
              {t("remember_to_remove_your_id")}
            </Typography>
          </Box>
        )}
      </Modal>
    </>
  );
};

LogOut.propTypes = {
  autologoutTimeoutInSeconds: PropTypes.number,
  hidden: PropTypes.bool,
  path: PropTypes.string,
};

LogOut.defaultProps = {
  autologoutTimeoutInSeconds: AUTOLOGOUT_INITIAL_VALUE_IN_SECONDS,
  hidden: false,
  path: undefined,
};

export default LogOut;
