import Matercolor from "matercolors";
import mapValues from "lodash/mapValues";

/**
 *  Creates a material-ui complaint palette, using the primary
 *  and secondary colors as base (if they are defined).
 */
const getPalette = (basePrimaryColor = "#4671A9", baseSecondaryColor = "#979AB7") => {
  const palette = {};

  if (basePrimaryColor) {
    palette.primary = colorToMuiPalette(basePrimaryColor);
  }

  if (baseSecondaryColor) {
    palette.secondary = colorToMuiPalette(baseSecondaryColor);
  }

  return palette;
};

export default getPalette;

const colorToMuiPalette = (color) => {
  const Color = new Matercolor(color);
  const matercolorPalette = Color.palette();
  const matercolorShades = Color.shades("primary");
  const palette = mapValues(matercolorPalette.primary, ({ hex }) => hex);
  const shades = mapValues(matercolorShades, ({ hex }) => hex);

  return { ...palette, ...shades };
};
