import Container from "components/Container";
import ColumnConstraints from "components/Page/ColumnConstraints";

/**
 * @deprecated the Page component should be used for layout purposes
 */

const LayoutContent = ({ columnConstraints, children, ...rest }) => (
  <Container flex="1 1 0" {...rest}>
    <ColumnConstraints constraints={columnConstraints}>{children}</ColumnConstraints>
  </Container>
);

export default LayoutContent;
