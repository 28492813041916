import { createTheme } from "@mui/material";
import merge from "lodash/merge";
import getEnv from "utils/getEnv";
import getPalette from "./getPalette";

const theme = getEnv("theme", {});

const palette = getPalette(theme.palette?.basePrimary, theme.palette?.baseSecondary);

const muiTheme = createTheme(merge({ palette }, theme));

export default muiTheme;
