const AllSetIcon = ({ color = "var(--primary-color)", ...props }) => (
  <svg fill="none" height="1em" viewBox="0 0 56 56" width="1em" {...props}>
    <path
      d="M51.333 25.853V28A23.334 23.334 0 1137.497 6.673"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
    />
    <path d="M51.333 9.333L28 32.69l-7-7" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} />
  </svg>
);

export default AllSetIcon;
