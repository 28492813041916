import * as base from "./images/base";
import * as baseKiosk from "./images/baseKiosk";
import * as citymd from "./images/citymd";
import * as citymdV2 from "./images/citymdV2";
import * as summit from "./images/summit";
import * as summitV2 from "./images/summitV2";

const images = {
  base,
  baseKiosk,
  citymd,
  citymdV2,
  summit,
  summitV2,
};

export const getImage = (imagePath) => {
  const [imageSet, imageName] = imagePath.replace(/\.[^.]+$/, "").split("/");

  return images[imageSet][imageName];
};

export default images;
