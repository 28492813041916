import { useEffect } from "react";

const useOnUserInteraction = (onUserInteraction) => {
  useEffect(() => {
    window.addEventListener("focus", onUserInteraction);
    window.addEventListener("keydown", onUserInteraction);
    window.addEventListener("mousedown", onUserInteraction);
    window.addEventListener("touchmove", onUserInteraction);
    window.addEventListener("touchstart", onUserInteraction);

    return () => {
      window.removeEventListener("focus", onUserInteraction);
      window.removeEventListener("keydown", onUserInteraction);
      window.removeEventListener("mousedown", onUserInteraction);
      window.removeEventListener("touchmove", onUserInteraction);
      window.removeEventListener("touchstart", onUserInteraction);
    };
  }, [onUserInteraction]);
};

export default useOnUserInteraction;
