import { Children } from "react";
import upperFirst from "lodash/upperFirst";

/**
 * This component makes it easy to implement the "Configuration components" pattern,
 * which consist of a tree of components that is parsed and resolved to a config object, that later
 * is used to render the final view using a renderer. This allows the creation of declarative
 * components.
 */
const Configuration = ({ children, renderer: Renderer, ...rest }) => {
  const config = getConfigFromChildren(children);

  return <Renderer config={config} {...rest} />;
};

export default Configuration;

/**
 * Creates a configuration item, which is a component that gathers props into a
 * specific key, in the final configuration object.
 *
 * @param {string} name - The name that will be used as key in the configuration object
 * @param {Object} options
 * @param {function} options.mapProps - Map the props the component receives to any arbitrary value
 */
export const createConfigurationItem = (name, options = {}) => {
  const ConfigurationItem = () => null;

  ConfigurationItem.displayName = upperFirst(name);

  ConfigurationItem[nameSym] = name;

  if (options.mapProps) {
    ConfigurationItem[mapPropsSym] = options.mapProps;
  }

  return ConfigurationItem;
};

const nameSym = Symbol("name");
const mapPropsSym = Symbol("mapProps");

const getConfigFromChildren = (children) =>
  Children.toArray(children).reduce((configSoFar, child) => {
    if (!child?.type?.[nameSym]) return configSoFar;

    const configItemValue = child.type[mapPropsSym] ? child.type[mapPropsSym](child.props) : child.props;

    return { ...configSoFar, [child.type[nameSym]]: configItemValue };
  }, {});
