import { createContext, useMemo, useContext } from "react";
import { useI18nContext } from "hooks/useTranslation";

const StepConfigContext = createContext({
  customAlert: {},
});

export const withStepConfig = (WrappedComponent) =>
  function ({ stepConfig = {}, ...rest }) {
    const { locale } = useI18nContext();
    const config = useMemo(() => {
      const customAlert = stepConfig.customAlert || {};

      return {
        customAlert: {
          text: customAlert[`text_${locale}`],
          type: customAlert.notification_type,
        },
      };
    }, [stepConfig, locale]);

    return (
      <StepConfigContext.Provider value={config}>
        <WrappedComponent {...rest} />
      </StepConfigContext.Provider>
    );
  };

export const useStepConfig = () => useContext(StepConfigContext);
