const FrontDeskIcon = ({ color = "var(--primary-color)", ...props }) => (
  <svg fill="none" height="1em" viewBox="0 0 40 31" width="1em" {...props}>
    <path
      d="M4.286 21.238h32.143v7.143c0 1.183-.96 2.143-2.143 2.143H6.429a2.143 2.143 0 01-2.143-2.143v-7.143z"
      fill={color}
    />
    <rect fill={color} height={2.143} rx={1.071} width={40} y={19.095} />
    <path
      d="M22.857 17.429s-.357-2.97-.994-4.041c-.636-1.072-1.499-1.674-2.399-1.674H12.68c-.9 0-1.763.602-2.4 1.674-.636 1.072-.993 2.525-.993 4.04M16.131 9.214a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.143}
    />
    <rect fill={color} height={1.429} rx={0.714} width={2.143} x={30} y={11.714} />
    <path d="M34.286 17.786a3.214 3.214 0 10-6.429 0" stroke={color} strokeLinecap="round" strokeWidth={1.429} />
  </svg>
);

export default FrontDeskIcon;
