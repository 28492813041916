import { useState, useRef } from "react";
import LanguageIcon from "@mui/icons-material/Language";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import useTranslation, { useSwitchLocale } from "hooks/useTranslation";
import { SUPPORTED_LANGUAGES_FOR_KIOSK } from "utils/initTranslationsHelper";
import requestToServer from "utils/requestToServer";
import Button from "components/Button";
import I18n from "i18n-js";

const LanguageSelector = ({ skipReload }) => {
  const t = useTranslation("components.header");
  const switchLocale = useSwitchLocale();

  const containerRef = useRef();
  const [language, setLanguage] = useState(
    SUPPORTED_LANGUAGES_FOR_KIOSK.find((supportedLanguage) => supportedLanguage.value === I18n.currentLocale()),
  );
  const [languageMenuAnchorElement, setLanguageMenuAnchorElement] = useState(null);

  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    setLanguageMenuAnchorElement(null);

    const localizedUrl = new URL(window.location.href);
    localizedUrl.searchParams.set("locale", selectedLanguage.value);

    if (skipReload) {
      requestToServer({ path: localizedUrl, method: "GET" }).then(() => {
        switchLocale(selectedLanguage.value);
      });
    } else {
      window.location.replace(localizedUrl);
    }
  };

  const handleLanguageButtonClick = (event) => {
    setLanguageMenuAnchorElement(event.currentTarget);
  };

  return (
    <Box component="span" ref={containerRef}>
      <Button aria-label={language.label} onClick={handleLanguageButtonClick} variant="text">
        <Box sx={{ alignItems: "center", display: { xs: "none", sm: "flex" } }}>
          <LanguageIcon sx={{ paddingRight: 1 }} titleAccess={t("language_selector")} />
          <Typography variant="button">{language.label}</Typography>
        </Box>
        <Typography sx={{ display: { xs: "block", sm: "none" }, textTransform: "uppercase" }} variant="button">
          {language.value}
        </Typography>
      </Button>
      <Menu
        PaperProps={{ sx: { borderRadius: "16px", width: "162px" } }}
        anchorEl={languageMenuAnchorElement}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        container={containerRef.current}
        getContentAnchorEl={null}
        onClose={() => setLanguageMenuAnchorElement(null)}
        open={Boolean(languageMenuAnchorElement)}
        transformOrigin={{
          vertical: -8,
          horizontal: "center",
        }}
      >
        {SUPPORTED_LANGUAGES_FOR_KIOSK.map((supportedLanguage) => (
          <MenuItem key={supportedLanguage.value} onClick={() => handleLanguageChange(supportedLanguage)}>
            <Radio
              checked={language.value === supportedLanguage.value}
              inputProps={{ "aria-label": language.value }}
              name="language"
              value={supportedLanguage.value}
            />
            <Box sx={{ paddingLeft: 1 }}>{supportedLanguage.label}</Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default LanguageSelector;
