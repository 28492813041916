import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

/**
 * @deprecated the Page component should be used for layout purposes
 */

const LayoutActions = ({ children, className, legend, ...props }) => (
  <Box className={className} {...props}>
    {legend && (
      <Typography component="span" variant="body2">
        <Box fontWeight="fontWeightBold" pb={2} textAlign="center">
          {legend}
        </Box>
      </Typography>
    )}
    {children}
  </Box>
);

export default LayoutActions;
