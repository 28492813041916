const NextIcon = ({ color = "var(--primary-color)", ...props }) => (
  <svg fill="none" height="1em" viewBox="0 0 44 44" width="1em" {...props}>
    <path
      d="M24.833 34.833L41.333 22l-16.5-12.834v25.667zM3.667 34.833L20.167 22 3.667 9.167v25.666z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    />
  </svg>
);

export default NextIcon;
