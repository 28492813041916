const ChairIcon = ({ color = "var(--primary-color)", ...props }) => (
  <svg fill="none" height="1em" viewBox="0 0 32 32" width="1em" {...props}>
    <path
      d="M5.333 24v4h4v-4h13.334v4h4v-8H5.333v4zm20-10.667h4v4h-4v-4zm-22.666 0h4v4h-4v-4zm20 4H9.333V6.667C9.333 5.2 10.533 4 12 4h8c1.467 0 2.667 1.2 2.667 2.667v10.666z"
      fill={color}
    />
  </svg>
  );

export default ChairIcon;
