import { memo, isValidElement } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { getImage } from "theme/images";

const Icon = memo(({ image, alt }) => {
  if (isValidElement(image)) return <Box pb={2}>{image}</Box>;

  const Renderer = getRenderer(image);
  return <Renderer alt={alt} image={image} />;
});

export default Icon;

const isFilePath = (path) => path.match(/.*\/.*/);
const isVideo = (path) => path.match(/\.mp4/);

const getRenderer = (image) => {
  if (!isFilePath(image)) {
    return renderers.Icon;
  }

  if (isVideo(image)) {
    return renderers.Video;
  }

  return renderers.Image;
};

const renderers = {
  Icon: ({ image, alt }) => {
    const theme = useTheme();

    return (
      <span aria-label={alt} role="img" style={theme.components.Icon?.style}>
        {image}
      </span>
    );
  },
  Image: ({ image, alt }) => {
    const theme = useTheme();

    return (
      <span style={theme.components.Icon?.style}>
        <img alt={alt} src={getImage(image)} />
      </span>
    );
  },
  Video: ({ image }) => (
    <Box autoPlay borderRadius="32px" component="video" height="350px" loop mb={2} muted textAlign="center">
      <source src={getImage(image)} type="video/mp4" />
    </Box>
  ),
};
