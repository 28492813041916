const CheckMarkIcon = ({ color = "var(--primary-color)", ...props }) => (
  <svg height="1em" viewBox="0 0 31 24" width="1em" {...props}>
    <path
      d="M.358 13.199a1.275 1.275 0 01-.358-.83c0-.236.12-.591.358-.828l1.669-1.658a1.158 1.158 0 011.67 0l.118.118 6.558 6.987a.579.579 0 00.835 0L27.185.528h.119a1.158 1.158 0 011.67 0l1.668 1.658a1.14 1.14 0 010 1.657L11.565 23.501c-.238.237-.477.356-.834.356-.358 0-.596-.119-.835-.356l-9.3-9.947-.238-.355z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

export default CheckMarkIcon;
