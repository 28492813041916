import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "components/Alert";
import Container from "components/Container";
import { useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import ColumnConstraints from "components/Page/ColumnConstraints";
import Icon from "./Icon";

const HeaderContainer = styled(Container)(() => ({
  "&:empty": {
    paddingBottom: 0,
  },
}));

/**
 * @deprecated the Page component should be used for layout purposes
 */

const LayoutHeader = ({
  title,
  subtitle,
  children,
  icon,
  alerts,
  maintainTopSpacingWhenNoIcon,
  classes = {},
  columnConstraints,
}) => {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up("sm"));
  const withAdditionalTopSpacing = icon || maintainTopSpacingWhenNoIcon;
  const titleVariants = upSm ? { big: "h4", normal: "h5" } : { big: "h5", normal: "h6" };

  return (
    <HeaderContainer component="header" pb={2} pt={withAdditionalTopSpacing ? 4 : 2} textAlign="center">
      {alerts && alerts.length > 0 && (
        <ColumnConstraints constraints={columnConstraints}>
          {alerts.map((alert) => (
            <Box key={alert.children || alert} pb={2} textAlign="left">
              <Alert
                key={alert.children || alert}
                severity={alert.severity || "error"}
                variant={alert.variant || "outlined"}
              >
                {alert.children || alert}
              </Alert>
            </Box>
          ))}
        </ColumnConstraints>
      )}

      {icon && (
        <Box>
          <Icon alt={title} image={icon} />
        </Box>
      )}

      {title && (
        <Typography
          className={classes.title}
          color="textPrimary"
          component="h1"
          variant={withAdditionalTopSpacing ? titleVariants.normal : titleVariants.big}
        >
          {title}
        </Typography>
      )}

      {subtitle && (
        <Box my={1}>
          <Typography color="textSecondary" component="h2" variant="body1">
            {subtitle}
          </Typography>
        </Box>
      )}

      {children}
    </HeaderContainer>
  );
};

export default LayoutHeader;
