import PropTypes from "prop-types";
import Configuration, { createConfigurationItem } from "components/Configuration";
import { DEFAULT_COLUMN_CONSTRAINTS } from "components/Page/ColumnConstraints";
import PageRenderer from "./PageRenderer";
import t from "./translate";

const Page = ({ children }) => <Configuration renderer={PageRenderer}>{children}</Configuration>;

export default Page;

const mapChildrenToLiteralOptions = { mapProps: (props) => props.children };

Page.Layout = createConfigurationItem("layout");
Page.Layout.propTypes = {
  className: PropTypes.string,
  columnConstraints: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.number),
    PropTypes.oneOf([DEFAULT_COLUMN_CONSTRAINTS]),
  ]),

  maintainTopSpacingWhenNoIcon: PropTypes.bool,
};

Page.Header = createConfigurationItem("header");
Page.Header.propTypes = {
  autologoutTimeoutInSeconds: PropTypes.number,
  hideLanguageSelector: PropTypes.bool,
  hideLogoutButton: PropTypes.bool,
};
Page.Header.defaultProps = {
  hideLanguageSelector: false,
  hideLogoutButton: false,
};

Page.Logo = createConfigurationItem("logo");
Page.Logo.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

Page.Title = createConfigurationItem("title");
Page.Title.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  variant: PropTypes.oneOf(["primary", "secondary"]),
};
Page.Title.defaultProps = {
  variant: "primary",
};

Page.Subtitle = createConfigurationItem("subtitle", mapChildrenToLiteralOptions);
Page.Subtitle.propTypes = {
  children: PropTypes.node.isRequired,
};

Page.Icon = createConfigurationItem("icon", mapChildrenToLiteralOptions);
Page.Icon.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Page.Content = createConfigurationItem("content");
Page.Content.propTypes = {
  children: PropTypes.node,
  disableGutters: PropTypes.bool,
};
Page.Content.defaultProps = {
  children: null,
};

Page.RequiredCopy = createConfigurationItem("requiredCopy", { mapProps: () => true });

Page.Note = createConfigurationItem("note");
Page.Note.propTypes = {
  children: PropTypes.node.isRequired,
  placement: PropTypes.oneOf(["top", "bottom"]),
  align: PropTypes.oneOf(["center", "left"]),
  light: PropTypes.bool,
  component: PropTypes.string,
};
Page.Note.defaultProps = {
  placement: "top",
  align: "center",
  light: true,
  component: undefined,
};

Page.Card = createConfigurationItem("card");
Page.Card.propTypes = {
  children: PropTypes.node.isRequired,
};
Page.Actions = createConfigurationItem("actions");
Page.Actions.propTypes = {
  children: PropTypes.node.isRequired,
};
Page.ButtonPrimary = createConfigurationItem("buttonPrimary");
Page.ButtonPrimary.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  type: PropTypes.string,
};
Page.ButtonPrimary.defaultProps = {
  children: t("button_primary_default_text"),
};

Page.ButtonSecondary = createConfigurationItem("buttonSecondary");
Page.ButtonSecondary.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
};
Page.ButtonSecondary.defaultProps = {
  variant: "secondary",
};

Page.ButtonTertiary = createConfigurationItem("buttonTertiary");
Page.ButtonTertiary.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  href: PropTypes.string,
};
Page.ButtonTertiary.defaultProps = {
  variant: "text",
};

Page.Alert = createConfigurationItem("alert");
const alertShape = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  severity: PropTypes.oneOf(["error", "info", "warning", "success"]),
  variant: PropTypes.oneOf(["standard", "filled", "outlined"]),
};
Page.Alert.propTypes = {
  ...alertShape,
  alerts: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape(alertShape), PropTypes.string])),
};

Page.Form = createConfigurationItem("form");
Page.Form.propTypes = {
  onSubmit: PropTypes.func,
  /**
   * If passed, under the hood it will use FormikForm instead of Form
   */
  formik: PropTypes.bool,
  action: PropTypes.string,
  method: PropTypes.string,
};
Page.DefaultColumnConstraints = DEFAULT_COLUMN_CONSTRAINTS;
