import Typography from "@mui/material/Typography";

const PageNote = ({ children, light, small, ...props }) => {
  const variant = small ? "body2" : "body1";

  return (
    <Typography
      {...props}
      component="div"
      sx={{ fontWeight: light ? "normal" : "bold", color: light ? "var(--palette-text-tertiary)" : "inherit" }}
      variant={variant}
    >
      {children}
    </Typography>
  );
};

export default PageNote;

PageNote.defaultProps = {
  light: true,
};
