import React, { useMemo, useState, useContext } from "react";
import initTranslationsHelper, { currentLocale } from "utils/initTranslationsHelper";

const I18nContext = React.createContext({
  locale: currentLocale(),
  switchLocale: () => {},
});

export const withI18nContext = (WrappedComponent) =>
  function (props) {
    const [locale, switchLocale] = useState(currentLocale());

    const value = useMemo(() => ({ locale, switchLocale }), [locale]);

    return (
      <I18nContext.Provider value={value}>
        <WrappedComponent {...props} />
      </I18nContext.Provider>
    );
  };

export const useI18nContext = () => useContext(I18nContext);

export const useSwitchLocale = () => useI18nContext().switchLocale;

const useTranslation = (scope) => {
  const { locale } = useI18nContext();

  return useMemo(() => initTranslationsHelper(scope, { locale }), [scope, locale]);
};

export default useTranslation;
