import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

const Container = ({ disableGutters, ...rest }) => {
  const { layoutPaddings } = useTheme();

  return <Box {...rest} px={disableGutters ? 0 : layoutPaddings} />;
};

export default Container;
