import { useEffect, useState, useCallback, useRef } from "react";

function useCountdown({
  countdownInterval = 1000,
  countdownFinishedIn = 0,
  enabled = true,
  onCountdownFinished = () => {},
  step = 1,
  timeout = 5,
}) {
  const intervalIdRef = useRef();
  const [countdown, setCountdown] = useState(timeout);
  const decrease = useCallback(() => setCountdown((currentCountDown) => currentCountDown - step), [step, setCountdown]);
  const resetCountdown = useCallback(() => setCountdown(timeout), [timeout]);
  const stop = useCallback(() => {
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = null;
  }, []);

  useEffect(() => {
    stop();
    if (enabled) {
      intervalIdRef.current = setInterval(decrease, countdownInterval);
    } else {
      resetCountdown();
    }
    return stop;
  }, [countdownInterval, decrease, enabled, resetCountdown, stop]);

  useEffect(() => {
    if (countdown <= countdownFinishedIn && intervalIdRef.current) {
      stop();
      onCountdownFinished();
    }
  }, [countdown, countdownFinishedIn, onCountdownFinished, stop]);
  return { countdown, resetCountdown };
}

export default useCountdown;
