import { useLayoutEffect, useState } from "react";

/**
 * Given a React ref pointing to a dom node, returns
 * the size of the dom node. If no ref is passed,
 * returns the size of the viewport. The shape of
 * the returned size is `{ width: number, height: number}`
 *
 * @param {React.RefObject} domRef
 */
export default function useSize(domRef) {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    const observer = getObserver(domRef);
    setSize(observer.getSize());

    observer.observe(() => setSize(observer.getSize()));
    return () => observer.disconnect();
  }, [domRef]);

  return size;
}

const getObserver = (domRef) => {
  if (!domRef) return new WindowSizeObserver();

  if (domRef.current) {
    return new ElementObserver(domRef.current);
  }

  return new EmptyRefObserver();
};

function WindowSizeObserver() {
  this.getSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  this.observe = (callback) => {
    this.callback = callback;
    window.addEventListener("resize", callback);
  };
  this.disconnect = () => window.removeEventListener("resize", this.callback);
}

function ElementObserver(element) {
  this.getSize = () => ({
    width: element?.clientWidth || 0,
    height: element?.clientHeight || 0,
  });

  this.observe = (callback) => {
    if (element) {
      this.resizeObserver = new ResizeObserver(callback);
      this.resizeObserver.observe(element);
    }
  };
  this.disconnect = () => this.resizeObserver?.disconnect();
}

function EmptyRefObserver() {
  this.getSize = () => ({
    width: 0,
    height: 0,
  });

  this.observe = () => {};
  this.disconnect = () => {};
}
