import { forwardRef } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import clsx from "clsx";
import useSize from "hooks/useSize";
import { HEADER_HEIGHT_IN_PX } from "components/Header";
import styles from "./LayoutStyles.module.scss";

/**
 * @deprecated the Page component should be used for layout purposes
 */

const Layout = forwardRef(({ component: Component, children, className, ...props }, ref) => {
  const { showHeader, minViewportHeight, layoutMaxWidth } = useTheme();
  const { height: viewportHeight } = useSize();
  const minHeight = minViewportHeight || viewportHeight;

  return (
    <Box
      className={clsx(styles.layout, className)}
      component={Component}
      maxWidth={layoutMaxWidth}
      minHeight={showHeader ? minHeight - HEADER_HEIGHT_IN_PX : minHeight}
      pt={showHeader ? `${HEADER_HEIGHT_IN_PX}px` : 0}
      ref={ref}
      {...props}
    >
      {children}
    </Box>
  );
});

Layout.defaultProps = {
  component: "main",
};

export default Layout;
