import { styled } from "@mui/material/styles";
import Button from "components/Button";

const ActionButton = styled(Button)(({ theme }) => ({
  width: "100%",
  "&:first-child": {
    marginRight: "var(--base-spacing)",
  },
  [theme.breakpoints.up("sm")]: {
    minWidth: "224px",
    width: "auto",
  },
}));

export default ActionButton;
