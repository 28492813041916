import { useMemo } from "react";
import PropTypes from "prop-types";
import uniqueId from "lodash/uniqueId";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import get from "lodash/get";
import FormField from "components/FormField";
import { Select as MuiSelect } from "@mui/material";

const Select = ({
  disableEmptyOption,
  disabled,
  emptyOption,
  error,
  helperText,
  id,
  label,
  onChange,
  options,
  required,
  value,
  ...props
}) => {
  const selectId = useMemo(() => id || uniqueId("select-"), [id]);

  const { undefined: optionsWithoutGroup = [], ...optionGroups } = groupBy(options, (option) => option?.groupName);

  return (
    <FormField
      disabled={disabled}
      error={error}
      fullWidth
      helperText={helperText}
      id={selectId}
      label={label}
      required={required}
    >
      <MuiSelect
        aria-invalid={error ? "true" : undefined}
        id={selectId}
        native
        onChange={onChange}
        value={value}
        {...props}
      >
        <option disabled={disableEmptyOption} style={disableEmptyOption ? { display: "none" } : {}} value="">
          {emptyOption || ""}
        </option>

        <Options options={optionsWithoutGroup} />

        {map(optionGroups, (groupOptions, groupName) => (
          <optgroup key={groupName} label={groupName}>
            <Options options={groupOptions} />
          </optgroup>
        ))}
      </MuiSelect>
    </FormField>
  );
};

Select.propTypes = {
  disableEmptyOption: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array, // A label and value pair
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        groupName: PropTypes.string,
      }),
    ]),
  ),
  emptyOption: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  helperText: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
};

Select.defaultProps = {
  disableEmptyOption: false,
  disabled: false,
  options: [],
  emptyOption: undefined,
  id: undefined,
  value: undefined,
  label: undefined,
  error: undefined,
  helperText: undefined,
  required: false,
  onChange: () => {},
  className: undefined,
  classes: {},
};

export default Select;

const Options = ({ options }) => (
  <>
    {options.map((option) => {
      const optionValue = getOptionValue(option);
      const optionLabel = getOptionLabel(option);

      return (
        <option key={optionValue.toString()} value={optionValue}>
          {optionLabel}
        </option>
      );
    })}
  </>
);

function getOptionValue(option) {
  if (Array.isArray(option)) {
    return option[1];
  }

  return get(option, "value", option);
}

function getOptionLabel(option) {
  if (Array.isArray(option)) {
    return option[0];
  }

  return get(option, "label", option);
}
