const CreditCardIcon = ({ color = "var(--primary-color)", ...props }) => (
  <svg fill="none" height="1em" viewBox="0 0 34 32" width="1em" {...props}>
    <path
      d="M29 5.333H5A2.667 2.667 0 002.333 8v16A2.667 2.667 0 005 26.667h24A2.667 2.667 0 0031.667 24V8A2.667 2.667 0 0029 5.333zM2.333 13.333h29.334"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
    />
  </svg>
);

export default CreditCardIcon;
